@use "styles" as *;

.paragraph,
.h1Title,
.h2Title,
.h3Title,
.h4Title,
.h5Title,
.h6Title {
  white-space: pre-line;
  text-align: center;

  &.alignedStart {
    text-align: start;
  }
  &.alignedEnd {
    text-align: end;
  }
}

.hrLine {
  margin: 24px 0;
  border: 0.5px solid $light-gray;
}

.icon {
  display: inline-block;
}

.list {
  margin-inline-start: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &.alignedStart {
    align-items: flex-start;
  }
  &.alignedEnd {
    align-items: flex-end;
  }
}

.gradient,
.gradientRegular {
  @include kisses-gradient;
  text-transform: uppercase;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradientRegular {
  font-weight: $font-weight-regular;
}

.mediaWrapper {
  position: relative;
  aspect-ratio: 1;
  img {
    object-fit: cover;
  }
}

.containerTableMobile {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include sm-desktop {
    display: none;
  }
}

.containerTableDesktop {
  @include mobile-only {
    display: none;
  }
}

.table {
  @include rounded-8;
  @include container-shadow;
  backdrop-filter: blur(10px);
  margin: 0 auto;
  &.alignedStart {
    margin-inline-start: 0;
  }
  &.alignedEnd {
    margin-inline-end: 0;
  }
}

.innerTable {
  padding: 12px 16px 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 1px;
    background-color: $very-light-gray;
  }
}

.titleTable {
  @include text-xs-bold-uppercase;
}

.descriptionTable {
  @include text-xs-regular;
}

.tbody {
  .tr {
    &:last-child::before {
      height: 0;
    }
  }
}

.tr {
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $very-light-gray;
  }
}

.th {
  padding: 16px;
  @include text-xs-bold-uppercase;
  position: relative;
}

.td {
  padding: 12px 16px 16px;
  @include text-xs-regular;
  vertical-align: top;
  position: relative;
}

.td:not(:last-child)::before,
.th:not(:last-child)::before {
  content: "";
  display: block;
  position: absolute;
  inset-inline-end: 0;
  bottom: 0;
  width: 1px;
  height: calc(100% + 3px);
  background-color: $very-light-gray;
}

.h1Title {
  @include text-xxl-regular;
  @include md-desktop {
    @include text-mega-regular;
  }

  &.h1TitleHighlighted {
    @include text-big-title;
  }
}

.h2Title {
  @include text-xxl-regular;
  @include md-desktop {
    @include text-3xl-regular;
  }

  &.h2TitleHighlighted {
    @include text-medium-title;
  }
}

.h3Title {
  @include text-xl-regular;
  @include md-desktop {
    @include text-3xl-regular;
  }

  &.h3TitleHighlighted {
    @include text-small-title;
  }
}

.h4Title {
  @include text-m-regular;
  font-weight: inherit;

  &.h4TitleHighlighted {
    strong {
      @include text-m-bold;
    }
  }
}

.paragraph {
  @include text-s-regular;
  font-weight: inherit;

  &.paragraphHighlighted {
    strong {
      @include text-s-bold;
    }
  }

  .link {
    text-decoration: underline;
    font-size: inherit;
  }
}

.h5Title {
  @include text-xs-regular;
  font-weight: inherit;

  &.h5TitleHighlighted {
    @include text-xs-regular;
    strong {
      @include text-xs-bold;
    }
  }
}

.h6Title {
  @include text-xxs-regular;
  font-weight: inherit;

  &.h6TitleHighlighted {
    strong {
      @include text-xxs-bold;
    }
  }
}

.linkVariant,
.buttonVariant {
  display: inline-block;
  width: 100%;
  @include sm-desktop {
    width: fit-content;
  }
}

.button {
  font-size: inherit;
  line-height: inherit;
  @include ltr-only {
    letter-spacing: inherit;
  }
}
