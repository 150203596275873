@use "styles" as *;

@keyframes rotate-hourglass {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(5deg);
  }
  55% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(20px);
}
.container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.spinner {
  position: relative;
  width: 72px;
  height: 72px;
  margin: auto;
}
.circle {
  @include kisses-gradient;
  aspect-ratio: 1;
  border-radius: 999px;
  mask-image: radial-gradient(circle, transparent 34px, black 35px);
}
.iconContainer {
  display: flex;
  position: absolute;
  inset: 0;
  align-items: center;
  margin: auto;
  width: 30px;
  height: 40px;
  animation: rotate-hourglass 2s infinite;
}
