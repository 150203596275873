@use "styles" as *;

.container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.containerColumn {
  display: flex;
  flex-direction: column;
}

.displayPrice {
  @include text-s-bold;
}

.discount {
  color: $pink;
  @include text-xxs-bold;
  line-height: unset;

  &.discountProductGrid {
    order: -1;
    @include text-xs-bold;
    font-size: clamp(0.5rem, 2.8vw, 0.75rem);
  }
}

.originalPrice {
  @include text-xxs-regular;

  &.originalPriceProductGrid {
    @include text-xs-regular;
    font-size: clamp(0.5rem, 2.8vw, 0.75rem);
  }
}
