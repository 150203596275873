@use "styles" as *;

.visuallyHidden {
  position: absolute;
  @include visuallyHidden;
}

.start,
.stop {
  text-align: start;
}

.start {
  cursor: url("../../../public/img/play.svg"), auto;
}

.stop {
  cursor: url("../../../public/img/pause.svg"), auto;
}

.title {
  @include text-xl-light;
  text-align: unset;
  @include sm-desktop {
    @include text-xxl-extralight;
  }
}

.titleHighlighted {
  @include text-xl-light;
  strong {
    @include text-xl-semibold;
  }
  @include sm-desktop {
    @include text-xxl-extralight;
    strong {
      @include text-xxl-semibold;
    }
  }
}
