@use "styles" as *;

.container {
  display: flex;
  align-items: center;
  gap: 4px;

  @include tablet {
    gap: 8px;
  }
}

.containerColumn {
  display: flex;
  flex-direction: column;
}

.displayPrice {
  @include text-s-bold;
}

.discount {
  color: $pink;
  @include text-xs-bold;
  font-size: clamp(0.5rem, 2.8vw, 0.75rem);
}

.originalPrice {
  @include text-xs-regular;
  font-size: clamp(0.5rem, 2.8vw, 0.75rem);
}
