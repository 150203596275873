@use "styles" as *;

.titleAndNewsletterContainer {
  grid-column: 1 / span 12;
  margin-bottom: 58px;

  @include sm-desktop {
    padding: 0;
    margin-bottom: 28px;
    grid-column: 1 / span 4;
    grid-row: 1 / span 2;
  }

  .wrapperTitle {
    margin-bottom: 23px;
    @include sm-desktop {
      margin-bottom: 35px;
    }

    .subtitle {
      @include text-l-semibold;
      @include sm-desktop {
        @include text-xxl-semibold;
      }
    }

    .highlightedText {
      margin-top: 8px;
      @include text-m-semibold;
      color: $fucsia;
      @include sm-desktop {
        @include text-l-semibold;
      }
    }
  }

  .newsletterCheckbox {
    @include text-xs-regular;
    line-height: 140%;
    margin-top: 20px;
    @include sm-desktop {
      margin-top: 32px;
    }
  }

  .link {
    display: block;
    width: fit-content;
    margin-inline-start: 24px;
    @include text-xs-regular;
    @include text-underline;
  }

  .submitField {
    margin-top: 20px;
    @include sm-desktop {
      margin-top: 32px;
    }
  }
}
