@use "styles" as *;

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
}

.doubleOptInTitle {
  @include text-s-regular-uppercase;
}

.doubleOptInDescription {
  @include text-m-regular;
}

.input,
.submit {
  width: 100%;
}

.icon {
  stroke-width: 4;
}

//dialog
.panel {
  height: 100svh;
  @include sm-desktop {
    margin: auto;
    max-width: 40%;
    height: auto;
    max-height: 100svh;
  }
  @include md-desktop {
    max-width: 30%;
  }
}

.title {
  @include text-s-bold-uppercase;
}

div.content {
  position: relative;
  overflow: hidden;
}
