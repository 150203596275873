@use "styles" as *;

.container {
  --bg-color: transparent;
  padding: 40px 0;
  overflow: hidden;

  &.white {
    --bg-color: #{$white};
    background-color: $white;
  }
  &.pink {
    --bg-color: #{$light-pink};
    background-color: $light-pink;
  }
}

.title {
  @include text-big-title;
  text-align: center;
  margin-bottom: 40px;
  // @include sm-desktop {
  //   margin-bottom: 80px;
  // }
}

.cartTitle {
  @include text-s-regular-uppercase;
  font-weight: 400;
  text-align: center;
  margin-bottom: 40px;
}

.relativeContainer {
  position: relative;
  @media (min-width: #{$screen-lg-desktop-min + (2 * $md-min-margin)}) {
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: calc((100vw - 1920px) / 2);
      z-index: 2;
    }
    &::before {
      right: 0;
      transform: translateX(100%);
      background: linear-gradient(270deg, var(--bg-color) 0%, rgba(255, 245, 245, 0) 100%);
    }
    &::after {
      left: 0;
      transform: translateX(-100%);
      background: linear-gradient(90deg, var(--bg-color) 0%, rgba(255, 245, 245, 0) 100%);
    }
  }
}

.absoluteContainer {
  position: absolute;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  display: none;
  @include sm-desktop {
    display: flex;
  }
}

.arrowL,
.arrowR {
  width: 40px;
  height: 40px;
  @include glass-gradient;
  @include glass-shadow;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  transition: all 0.3s ease-in-out;
  &:disabled {
    opacity: 0;
    visibility: hidden;
  }
}
