@use "styles" as *;

.container {
  display: flex;
  align-items: center;
}

.numberOfRatings {
  @include text-xxs-regular;

  display: inline;
  margin-inline-start: 2px;
}
