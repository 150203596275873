@use "styles" as *;

.quantitySelector {
  @include button-shadow;
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: $white;
  color: $dark;
  display: flex;
  gap: 12px;
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;
}

.textQty {
  @include text-s-bold-uppercase-cta;
}

.simpleQtyBtn {
  &[disabled] {
    cursor: initial;
    opacity: 0.5;
  }
}

.quantityForm {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: opacity 300ms ease-in-out 0ms;
}

.disabledQuantityForm {
  opacity: 0.5;
}

.quantityInput {
  display: inline-block;
  border: 0 none;
  padding: 0;
  width: 30px;
  appearance: none;
  -moz-appearance: textfield;
  line-height: 14px;
  @include text-xs-bold-uppercase-cta;
  text-align: center;
  height: 24px;
  transition: border-color 300ms ease-in-out 0ms;
  transform: translate3d(0, 0, 0);
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @include sm-desktop {
    @include rounded-4;
    border: 0.5px solid $gray;
  }

  &:focus,
  &:focus-visible {
    @include sm-desktop {
      border: 0.5px solid $dark-gray;
      outline: none;
    }
  }
}

.quantityButton {
  display: flex;
  padding: 4px;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.submitButton {
  display: none;
}
