@use "styles" as *;

.tags {
  display: flex;
  gap: 8px;
}

.tag {
  @include color-gradient-light;
  @include text-xs-semibold-uppercase-cta;
  padding: 3.2px 10px 4.8px 10px;
  border-radius: 9999px;
  overflow: hidden;
}

.percentageTag {
  @include color-gradient-light;
  @include text-xs-semibold-uppercase-cta;
  padding: 4px;
  @include rounded-50-percentage;
  flex-shrink: 0;
}

.label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
