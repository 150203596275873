@use "styles" as *;

.overlay {
  position: fixed;
  inset: 0;
  z-index: 99999;

  &.hasBackdrop {
    @include glass-gradient;
  }
}

.wrapper {
  max-width: 100svw;
  justify-content: center;
  align-items: center;
}

.panel {
  width: fit-content;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.top {
  position: static;
  display: flex;
  width: 100%;
}

.content {
  width: 100%;
}

.description {
  @include text-xs-regular;
}

.quantityForm {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}

.quantityFormInputs,
.quantityFormSubmit {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.quantityFormInputs {
  align-items: stretch;
  width: 325px;
}

.quantityInput {
  flex-basis: auto;
  flex-grow: 1;
  height: 100px;
  @include text-s-regular;
}

.inputField {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    @include text-s-regular;
    outline: none;
  }
}

.quantityButton {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block-start: 12px;
  padding: 4px;
  @include rounded-8();
  width: 50px;
  height: 50px;

  color: $dark;
  background: linear-gradient(0deg, $dark 50%, $white 50%);
  background-size: 100% 200%;
  @include button-shadow;
  transition: background-position-y 300ms ease-out, box-shadow 300ms ease-out, color 50ms ease-out 100ms;

  &:not(:disabled) {
    @media (any-hover: hover) {
      &:hover {
        box-shadow: initial;
        color: $white;
        background-position-y: 100%;
      }
    }
  }
}

.resetButton,
.submitButton {
  flex-grow: 1;
  flex-shrink: 0;
  width: calc(50% - 8px);
}
